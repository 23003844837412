import React, { useState, useMemo } from "react";

import {
  makeStyles,
  AppBar,
  Typography,
  Toolbar,
  Fab,
  Grid,
  LinearProgress,
  Snackbar,
  SnackbarContent,
} from "@material-ui/core";

import { useCollectionData } from "react-firebase-hooks/firestore";

import AddIcon from "@material-ui/icons/AddRounded";
import ErrorIcon from "@material-ui/icons/ErrorRounded";

import {
  InputScreenplayMetadata,
  listScreenplays,
  listSharedScreenplays,
  saveScreenplayMetadata,
  ScreenplayMetadata,
} from "../services/firebase";

import { EditScreenplayMetadataDialog } from "../dialog/EditScreenplayMetadata";
import { ScreenplayCard } from "../components/ScreenplayCard";
import { useHistory } from "react-router-dom";
import { ScreenplayPdfPreview } from "../dialog/PdfPreview";
import { useUser } from "../providers/UserProvider";
import { AccountMenu } from "../components/AccountMenu";
import { ShareScreenplayDialog } from "../dialog/ShareScreenplayDialog";

const useStyles = makeStyles((theme) => {
  return {
    title: {
      flexGrow: 1,
    },
    root: {},
    fab: {
      display: "flex",
      position: "absolute",
      alignItems: "center",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    screenplayGrid: {
      padding: theme.spacing(2),
      paddingBottom: 0,
    },
    snackbarError: {
      backgroundColor: theme.palette.error.dark,
    },
  };
});

export const Dashboard: React.FC = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useUser();

  const [showNewScreenplayDialog, setShowNewScreenplayDialog] = useState(false);
  const [ownedScreenplays, isLoadingOwnedScreenplays] = useCollectionData<
    ScreenplayMetadata
  >(listScreenplays(user!.uid), { idField: "id" });
  const [sharedScreenplays, isLoadingSharedScreenplays] = useCollectionData<
    ScreenplayMetadata
  >(listSharedScreenplays(user!.uid), { idField: "id" });
  const [
    previewScreenplay,
    setPreviewScreenplay,
  ] = useState<ScreenplayMetadata | null>(null);
  const [
    editScreenplayTitle,
    setEditScreenplayTitle,
  ] = useState<ScreenplayMetadata | null>(null);
  const [
    shareScreenplay,
    setShareScreenplay,
  ] = useState<ScreenplayMetadata | null>(null);
  const [error, setError] = useState<string | undefined>(undefined);

  const screenplays = useMemo(
    () => {
      const result = [];

      if(ownedScreenplays) {
        result.push(...ownedScreenplays);
      }

      if(sharedScreenplays) {
        result.push(...sharedScreenplays);
      }

      return result.sort((a, b) => a.updatedAt.seconds - b.updatedAt.seconds);
    },
    [ownedScreenplays, sharedScreenplays]
  );
  const isLoading = useMemo(
    () => isLoadingOwnedScreenplays || isLoadingSharedScreenplays,
    [isLoadingSharedScreenplays, isLoadingOwnedScreenplays]
  );

  const editScreenplay = (id: string) => history.push(`/edit/${id}`);

  const onSaveScreenplay = async (
    id: string | undefined,
    screenplay: InputScreenplayMetadata
  ) => {
    const newDoc = await saveScreenplayMetadata(id, screenplay);
    if (!id) editScreenplay(newDoc.id);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            Scenes Studio
          </Typography>
          <AccountMenu />
        </Toolbar>
      </AppBar>
      {isLoading && <LinearProgress />}
      {screenplays && (
        <Grid container spacing={3} className={classes.screenplayGrid}>
          {screenplays.map((sp) => (
            <Grid item xs={6} sm={4} md={2} key={sp.id}>
              <ScreenplayCard
                screenplay={sp}
                onEdit={() => editScreenplay(sp.id)}
                onPrint={() => setPreviewScreenplay(sp)}
                onEditTitlePage={() => setEditScreenplayTitle(sp)}
                onShare={() => setShareScreenplay(sp)}
              />
            </Grid>
          ))}
        </Grid>
      )}

      <Fab
        className={classes.fab}
        color="primary"
        onClick={() => setShowNewScreenplayDialog(true)}
      >
        <AddIcon />
      </Fab>

      <EditScreenplayMetadataDialog
        open={showNewScreenplayDialog}
        onClose={() => setShowNewScreenplayDialog(false)}
        onSave={(screenplay) => onSaveScreenplay(undefined, screenplay)}
      />

      {previewScreenplay && (
        <ScreenplayPdfPreview
          open={Boolean(previewScreenplay)}
          onClose={() => setPreviewScreenplay(null)}
          screenplay={previewScreenplay}
          onError={(error) => {
            setPreviewScreenplay(null);
            setError(error);
          }}
        />
      )}

      {editScreenplayTitle && (
        <EditScreenplayMetadataDialog
          open={Boolean(editScreenplayTitle)}
          onClose={() => setEditScreenplayTitle(null)}
          onSave={(screenplay) =>
            onSaveScreenplay(editScreenplayTitle.id, screenplay)
          }
          id={editScreenplayTitle.id}
        />
      )}

      {shareScreenplay && (
        <ShareScreenplayDialog
          screenplay={shareScreenplay}
          open={Boolean(shareScreenplay)}
          onClose={() => setShareScreenplay(null)}
        />
      )}

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={Boolean(error)}
        autoHideDuration={6000}
        onClose={() => setError(undefined)}
      >
        <SnackbarContent
          className={classes.snackbarError}
          message={
            <span>
              <ErrorIcon />
              {error}
            </span>
          }
        />
      </Snackbar>
    </div>
  );
};
