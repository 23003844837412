import React from "react";

import { Page, View, Text, StyleSheet } from "@react-pdf/renderer";
import { ScreenplayMetadata } from "../../services/firebase/screenplays";

interface TitlePageProps {
  screenplay: ScreenplayMetadata;
}

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Courier Prime',
    fontSize: '12pt',
    padding: '1in',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    textAlign: 'left'
  },
  titleBlock: {
    marginTop: '2in',
    textAlign: 'center',
    lineHeight: '2pt'
  },
  title: {
    textTransform: 'uppercase',
  },

  contactBlock: {
    position: 'absolute',
    top: '9in',
    left: '1in',
    textAlign: 'left'
  }
});

export const TitlePage: React.FC<TitlePageProps> = ({ screenplay }) => {
  return (
    <Page size="letter" wrap={false} style={styles.page}>
      <View style={styles.titleBlock}>
        <Text style={styles.title}>{screenplay.title}</Text>
        {screenplay.writtenBy && (
          <>
            <Text>written by</Text>
            <Text>{screenplay.writtenBy}</Text>
          </>
        )}
      </View>
      <View style={styles.contactBlock}>
        <Text>{screenplay.contactInformation}</Text>
      </View>
    </Page>
  );
};
