import {
  Avatar, CircularProgress, Fab, makeStyles, Tooltip
} from "@material-ui/core";
import CloudSavedIcon from "@material-ui/icons/CloudDoneRounded";
import HomeIcon from "@material-ui/icons/HomeRounded";
import SaveIcon from "@material-ui/icons/SaveRounded";
import SettingsIcon from "@material-ui/icons/SettingsRounded";
import { AvatarGroup } from "@material-ui/lab";
import randomColor from "randomcolor";
import React, { useEffect, useMemo, useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useHistory, useParams } from "react-router-dom";
import { createEditor, Node } from "slate";
import { withHistory } from "slate-history";
import { Slate, withReact } from "slate-react";
import { SyncElement, toSharedType, withCursor, withYjs } from "slate-yjs";
import { FirestorePersistence } from "y-firestore";
import { WebrtcProvider } from "y-webrtc";
import * as Y from "yjs";
import { LoadingContent } from "../components/LoadingContent";
import { ScreenplayEditor } from "../components/ScreenplayEditor";
import {
  ElementTypes,
  GroupElementTypes
} from "../components/types/elementTypes";
import { EditScreenplayMetadataDialog } from "../dialog/EditScreenplayMetadata";
import { withAutoFormatting } from "../hooks/autoFormatting";
import { useUser } from "../providers/UserProvider";
import {
  getScreenplay,
  GroupScreenplayNode,
  InputScreenplayMetadata,
  saveScreenplayMetadata,
  ScreenplayMetadata
} from "../services/firebase";





const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: "white",
    maxWidth: "60em",
    padding: "0 2em",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginLeft: "auto",
    marginRight: "auto",
    minHeight: "100vh",
    cursor: "text",
  },
  snackbarError: {
    backgroundColor: theme.palette.error.dark,
  },
  fabContainer: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
    flexDirection: "column",
    display: "flex",
    alignItems: "flex-end",
  },
  fabItem: {
    marginTop: theme.spacing(1),
  },
  userContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  avatarGroup: {
    marginRight: theme.spacing(1),
  },
}));

interface EditorRouterParams {
  documentId: string;
}

const emptyDocument: Array<GroupScreenplayNode> = [
  {
    type: GroupElementTypes.NavigationBlock,
    collapsed: false,
    children: [
      {
        type: ElementTypes.SceneHeader,
        children: [{ text: "" }],
      },
    ],
  },
];

export const ScreenplayEditorPage: React.FC = () => {
  const { documentId } = useParams<EditorRouterParams>();

  if (documentId) return <DocumentEditor documentId={documentId} />;

  return <div>Couldn't find that document...</div>;
};

interface DocumentEditorProps {
  documentId: string;
}

interface PeerState {
  alphaColor: string;
  color: string;
  name: string;
  photoUrl: string;
  connectedAt: number;
}

type PeerWithId = PeerState & { id: number };

const DocumentEditor: React.FC<DocumentEditorProps> = (props) => {
  const { documentId } = props;

  const { user } = useUser();
  const [peers, setPeers] = React.useState<Array<PeerWithId>>([]);
  const [sharedType, setSharedType] = React.useState<Y.Array<SyncElement>>();
  const [webRtc, setWebRtc] = React.useState<WebrtcProvider>();
  const [persistence, setPersistence] = React.useState<FirestorePersistence>();
  const [isLoading, setLoading] = React.useState(true);
  const [isChanged, setChanged] = useState(false);
  const [isSaving, setSaving] = useState(false);

  const connectedAt = React.useRef(Date.now());

  const handleSave = React.useCallback(() => persistence?.save(), [persistence]);


  React.useEffect(() => {
    if (!documentId) return;

    const doc = new Y.Doc();

    const sharedType = doc.getArray<SyncElement>("content");

    const persistence = new FirestorePersistence({
      ydoc: doc,
      documentRef: getScreenplay(documentId),
    });

    setPersistence(persistence);

    let destroyWebRtc: () => void;

    persistence.on("synced", () => {
      if (!persistence.synced) return;

      if (sharedType.length === 0) {
        toSharedType(sharedType, (emptyDocument as unknown) as Array<Node>);
      }

      setLoading(false);

      const webRtc = new WebrtcProvider(documentId, doc);
      setWebRtc(webRtc);
      destroyWebRtc = () => webRtc.destroy();
    });

    persistence.on("status", () => {
      switch(persistence.status) {
        case "Updated":
          setChanged(true);
          break;

        case "Saving":
          setSaving(true);
          break;

        case "NoChanges":
          setSaving(false);
          setChanged(false);
          break;
      }
    });

    // const indexedDb = new IndexeddbPersistence(documentId, doc);

    setSharedType(sharedType);

    return () => {
      if(destroyWebRtc)
        destroyWebRtc();
      persistence.destroy();
      // indexedDb.destroy();

      doc.destroy();
    };
  }, [documentId]);

  const editor = useMemo(
    // @ts-ignore
    () => {
      if (!sharedType || !webRtc?.awareness) return;

      return withCursor(
        withAutoFormatting(
          withYjs(withReact(withHistory(createEditor())), sharedType)
        ),
        webRtc.awareness
      );
    },
    [sharedType, webRtc]
  );

  const localState = React.useMemo(() => {
    const color = randomColor({
      luminosity: "dark",
      format: "rgba",
      alpha: 1,
    });

    // @ts-ignore
    const name = user?.displayName;
    const photoUrl = user?.photoURL;

    return { color, name, photoUrl, alphaColor: color.slice(0, -2) + "0.2)", connectedAt: connectedAt.current };
  }, [user?.displayName, user?.photoURL]);


  const isPrimary = React.useMemo(() => {
    const otherPeers = peers.filter((peer) => peer.connectedAt !== localState.connectedAt && peer.color !== localState.color && peer.name !== localState.name);

    return !otherPeers?.some((peer) => peer.connectedAt < connectedAt.current);

  }, [localState, peers]);

  React.useEffect(() => {
    if(!persistence) return;
    persistence!.enabled = isPrimary;
  }, [isPrimary, persistence]);

  React.useEffect(() => {
    if (!webRtc || !localState) return;

    webRtc.awareness.setLocalState(localState);
  }, [webRtc, localState]);

  React.useEffect(() => {
    if (!webRtc || !sharedType) return;

    webRtc.awareness.on(
      "update",
      ({
        added,
        updated,
        removed,
      }: {
        added: Array<number>;
        updated: Array<number>;
        removed: Array<number>;
      }) => {
        const states = webRtc.awareness.getStates();
        setPeers((peers) => {
          const allUpdatedIds = [...updated, ...added];
          const newPeers = [];

          for (const peer of peers) {
            if ([...removed, ...allUpdatedIds].some((id) => peer.id === id))
              continue;

            newPeers.push(peer);
          }

          for (const id of allUpdatedIds) {
            const peerState = states.get(id);
            if (peerState) {
              newPeers.push({
                id,
                name: peerState.name,
                alphaColor: peerState.alphaColor,
                color: peerState.color,
                photoUrl: peerState.photoUrl,
                connectedAt: peerState.connectedAt,
              });
            }
          }

          return newPeers.sort((a, b) => a.connectedAt - b.connectedAt);
        });
      }
    );

    console.info(`Connecting...`);
    webRtc.connect();

    return () => {
      console.info(`Disconnecting`);
      webRtc.disconnect();
      webRtc.destroy();
    };
  }, [webRtc, sharedType]);

  const [screenplay, setScreenplay] = useState<Array<Node>>([]);

  const [showTitlePageEditor, setShowTitlePageEditor] = useState(false);
  const [screenplayMetadata] = useDocumentData<ScreenplayMetadata>(
    getScreenplay(documentId)
  );

  useEffect(() => {
    const content = [screenplayMetadata?.title ?? "Scenes Studio"];

    if (isChanged) content.push("*");

    document.title = content.join("");
  }, [screenplayMetadata, isChanged]);

  const history = useHistory();

  const classes = useStyles();

  const onSaveMetadata = async (
    id: string | undefined,
    screenplay: InputScreenplayMetadata
  ) => {
    setSaving(true);
    setShowTitlePageEditor(false);
    await saveScreenplayMetadata(id, screenplay);
    setSaving(false);
  };

  if (isLoading) {
    return <LoadingContent title="Loading..." />;
  }

  return (
    <>
      {editor && (
        <div className={classes.container}>
          <Slate value={screenplay} editor={editor} onChange={setScreenplay}>
            <ScreenplayEditor />
          </Slate>
        </div>
      )}
      <div className={classes.fabContainer}>
        <div className={classes.userContainer}>
          <AvatarGroup max={4} className={classes.avatarGroup}>
            {peers.map((peer) => (
              <PeerAvatar peer={peer} key={peer.id} />
            ))}
          </AvatarGroup>
          
          <Fab
            size="small"
            disabled={!isChanged || isSaving || !isPrimary}
            onClick={handleSave}
          >
            {isSaving ? (
              <CircularProgress />
            ) : isChanged ? (
              <SaveIcon />
            ) : (
              <CloudSavedIcon />
            )}
          </Fab>
        </div>

        <Fab
          title="Title Page Settings"
          size="small"
          onClick={() => setShowTitlePageEditor(true)}
          className={classes.fabItem}
        >
          <SettingsIcon />
        </Fab>

        <Fab
          title="Home"
          size="small"
          onClick={() => history.push("/")}
          className={classes.fabItem}
        >
          <HomeIcon />
        </Fab>
      </div>
      <EditScreenplayMetadataDialog
        open={showTitlePageEditor}
        onClose={() => setShowTitlePageEditor(false)}
        onSave={(screenplay) => onSaveMetadata(documentId, screenplay)}
        id={documentId}
      />
    </>
  );
};

interface PeerAvatarProps {
  peer: PeerState;
}

const PeerAvatar: React.FC<PeerAvatarProps> = (props) => {
  const { name, color, photoUrl } = props.peer;

  return (
    <Tooltip title={name}>
      <Avatar
        alt={name}
        src={photoUrl}
        style={{ border: `${color} 3px solid` }}
      />
    </Tooltip>
  );
};
