import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";
import "firebase/functions";

import LZUTF8 from "lzutf8";

import { md5 } from "hash-wasm";
import { ProfileContent } from "functions/src";
import { ShareLink, ShareDocumentData, GetSharedDocument } from "functions/src/sharing/eventTypes";
import { ElementTypes, GroupElementTypes } from "../../components/types/elementTypes";

const googleProvider = new firebase.auth.GoogleAuthProvider();
export const signInWithGoogle = () => firebase
    .auth()
    .signInWithPopup(googleProvider);

export const signInWithEmail = (email: string, password: string) => firebase.auth().signInWithEmailAndPassword(email, password);

export const signOut = () => {
  firebase.auth().signOut().then(console.log);
};

let isRunningLocally = false;

export const configFirebase = (isLocal = false) => {
  firebase.initializeApp({
    apiKey: "AIzaSyAyiHD9ddufi5QM9rS_S_tg7X0L00Cr5UE",
    authDomain: "just-write-scenes.firebaseapp.com",
    projectId: "just-write-scenes",
    storageBucket: "just-write-scenes.appspot.com",
    messagingSenderId: "620551612203",
    appId: "1:620551612203:web:4d467c713a19b0ea9e876e",
    measurementId: "G-XQ5JHEM6M3",
  });

  if (isLocal && process.env.REACT_APP_TARGET === "local") {
    isRunningLocally = true;
    console.info(`On Localhost!`);
    firebase.auth().useEmulator("http://localhost:9099/");
    firebase.firestore().useEmulator("localhost", 8080);
    firebase.functions().useEmulator("localhost", 5001);
  }

  firebase
    .auth()
    .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
    .then(() => console.info(`Persistence updated`));

  firebase.analytics().setAnalyticsCollectionEnabled(!isLocal);
};

const getBucketPath = (documentId: string) => `screenplay/${documentId}`;


export interface BasicScreenplayNode {
  type: ElementTypes;
  children: [{text: string}];
}

export interface GroupScreenplayNode {
  type: GroupElementTypes;
  collapsed: boolean;
  children: Array<BasicScreenplayNode>;
}

export interface LoadScreenplayResult {
  document: Array<GroupScreenplayNode>;
  hash: string;
}

export const encodeDocument = async (
  document: Array<GroupScreenplayNode>
): Promise<{ content: string; hash: string }> => {
  const content = JSON.stringify({
    formatVersion: "1.0",
    document,
  });

  const [compressedContent, hash] = await Promise.all([
    new Promise<string>((resolve, reject) =>
      LZUTF8.compressAsync(
        content,
        {
          useWebWorker: true,
          outputEncoding: "Base64",
        },
        (result, error) => {
          if (error) {
            reject(error);
            return;
          }

          resolve(result);
        }
      )
    ),
    md5(content),
  ]);

  return { content: compressedContent, hash };
};

export const decodeDocument = async (
  data: string
): Promise<LoadScreenplayResult> => {
  const uncompressed = await new Promise<string>((resolve, reject) =>
    LZUTF8.decompressAsync(
      data,
      {
        inputEncoding: "Base64",
        outputEncoding: "String",
      },
      (result, error) => {
        if (error) {
          reject(error);
          return;
        }
        resolve(result);
      }
    )
  );

  const { document } = JSON.parse(uncompressed);

  return {
    document,
    hash: await md5(uncompressed),
  };
};

const loadDocumentFromStorage = async (
  uid: string,
  id: string
): Promise<string> => {
  if (isRunningLocally) {
    const data = localStorage.getItem(getBucketPath(id));

    if (!data) {
      throw new Error(`Could not download screenplay ${id}`);
    }

    return data;
  }

  const screenplayRef = firebase.storage().ref().child(getBucketPath(id));

  const downloadUrl = await screenplayRef.getDownloadURL().then();

  const request = new Request(downloadUrl);

  const response = await fetch(request);

  if (response.status !== 200) {
    throw new Error(`Could not download screenplay ${id}`);
  }

  return response.text();
};

export const loadScreenplayDocument = async (
  uid: string,
  id: string
): Promise<LoadScreenplayResult> => {
  let compressedContent = await loadDocumentFromStorage(uid, id);
  const result = await decodeDocument(compressedContent);

  return result;
};

interface WriteDocumentToStorageParams {
  uid: string;
  id: string;
  hash: string;
  content: string;
}

const writeDocumentToStorage = async ({
  uid,
  id,
  hash,
  content,
}: WriteDocumentToStorageParams): Promise<{ size: number }> => {
  const path = getBucketPath(id);

  if (isRunningLocally) {
    console.info(`Writing to local storage`);
    localStorage.setItem(path, content);

    return { size: content.length };
  }

  console.info(`Creating storage ref`);
  const storageRef = firebase.storage().ref();

  const screenplayRef = storageRef.child(path);

  const result = await screenplayRef.putString(content, undefined, {
    customMetadata: {
      formatVersion: "1.0",
      hash,
    },
  });

  return { size: result.metadata.size };
};

export const saveScreenplayDocument = async (
  uid: string,
  id: string,
  content: Array<GroupScreenplayNode>,
  hash: string
): Promise<void | string> => {
  const { hash: newHash, content: persistedData } = await encodeDocument(
    content
  );

  if (newHash === hash) return;

  try {
    const { size } = await writeDocumentToStorage({
      uid,
      id,
      content: persistedData,
      hash: newHash,
    });

    const doc = firebase
      .firestore()
      .collection(`user/${uid}/screenplay`)
      .doc(id);

    await doc.update({ updatedAt: new Date(), size });

    return newHash;
  } catch (ex) {
    console.error(ex);
    throw ex;
  }
};

export const getUserDoc = (uid: string) =>
  firebase.firestore().collection("user").doc(uid);

export const updateUserProfile = (uid: string, profile: ProfileContent) =>
  firebase
    .firestore()
    .collection(`user`)
    .doc(uid)
    .set({ profile }, { merge: true });

export const getShareLinks = (ownedBy: string, documentId: string) =>
  firebase
    .firestore()
    .collection(`link`)
    .where(`documentId`, `==`, documentId)
    .where("ownedBy", "==", ownedBy);

export const createShareLink = (link: ShareLink) =>
  firebase.firestore().collection("link").doc().set(link);

export interface ShareToRecord {
  email: string;
  canWrite: boolean;
}

export const shareScreenplay = async (
  documentId: string,
  shares: Array<ShareToRecord>
) => {
  const shareDocumentFn = firebase.functions().httpsCallable("shareDocument");

  for(const share of shares) {
    await shareDocumentFn({
      documentId,
      ...share,
    } as ShareDocumentData);
  }
};

export const addSharedScreenplay = async (params: GetSharedDocument): Promise<{documentId: string; canWrite: boolean}> => {
  const addSharedDocument = firebase.functions().httpsCallable("addSharedDocument");
  
  const result = await addSharedDocument(params);

  return result.data;
};
