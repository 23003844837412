import {
  Button, Dialog,
  DialogActions, DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemSecondaryAction, ListItemText, ListSubheader, Switch,
  TextField
} from "@material-ui/core";
import React, { KeyboardEvent } from "react";
import { ScreenplayMetadata, shareScreenplay, ShareToRecord } from "../services/firebase";


interface ShareScreenplayDialogProps {
  screenplay: ScreenplayMetadata;
  open: boolean;
  onClose: () => void;
}

export const ShareScreenplayDialog: React.FC<ShareScreenplayDialogProps> = (
  props
) => {
  const { screenplay } = props;
  const [shareTo, setShareTo] = React.useState<Array<ShareToRecord>>([]);
  const [email, setEmail] = React.useState<string>("");

  const onAddEmail = () => {
    if (!shareTo.some((r) => r.email === email.toLowerCase())) {
      setShareTo([
        ...shareTo,
        {
          canWrite: false,
          email,
        },
      ]);
      setEmail("");
    }
  };

  const onEmailReturnKeypress = (event: KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      onAddEmail();
    }
  };

  const onSetPermission = (email: string, canWrite: boolean) => {
    setShareTo(
      shareTo.map((share) => {
        if (share.email === email) {
          share.canWrite = canWrite;
        }

        return share;
      })
    );
  };

  const onSave = async () => {
    await shareScreenplay(screenplay.id, shareTo).catch(console.error);
    props.onClose();
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={props.open}>
      <DialogTitle>Share {props.screenplay.title}</DialogTitle>
      <DialogContent>
        <Grid container direction="row" alignContent="flex-end">
          <Grid item xs={11}>
            <TextField
              fullWidth
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              onKeyPress={onEmailReturnKeypress}
              label="Add Email"
            />
          </Grid>
          <Grid item xs={1} alignItems="flex-end">
            <Button fullWidth onClick={onAddEmail}>
              Add
            </Button>
          </Grid>
        </Grid>
        <List>
          <ListSubheader>New Users</ListSubheader>
          {shareTo.map((share) => (
            <ShareRecord
              {...share}
              updatePermissions={(canWrite) =>
                onSetPermission(share.email, canWrite)
              }
              key={share.email}
            />
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose}>Close</Button>
        <Button onClick={onSave} variant="contained" color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  );
};

interface ShareRecordProps extends ShareToRecord {
  updatePermissions: (canWrite: boolean) => void;
}

const ShareRecord: React.FC<ShareRecordProps> = (props) => (
  <ListItem>
    <ListItemText primary={props.email} />
    <ListItemSecondaryAction>
      <FormControlLabel
        label={`Can ${props.canWrite ? "Edit" : "View"}`}
        control={
          <Switch
            checked={props.canWrite}
            onChange={(event) => props.updatePermissions(event.target.checked)}
            color="primary"
          />
        }
      />
    </ListItemSecondaryAction>
  </ListItem>
);
