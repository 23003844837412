import { BasicScreenplayNode, GroupScreenplayNode } from "../../services/firebase";

export enum ElementTypes {
  SceneHeader = "sceneheader",
  Action = "action",
  Character = "character",
  Parenthetical = "parenthetical",
  Dialogue = "dialogue",
  Transition = "transition"
};

export enum GroupElementTypes {
  NavigationBlock = "navigationblock"
}

export function isGroupElement(value: GroupScreenplayNode | BasicScreenplayNode): value is GroupScreenplayNode {
  return value.type in Object.values(GroupElementTypes);
}