import {useLocation} from "react-router-dom";
import React, {useEffect} from "react";

import firebase from "firebase/app";
import "firebase/analytics";

const DEFAULT_TITLE = "Scenes Studio";


export const FirebaseAnalytics: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    const analytics = firebase.analytics();

    const page_path = location.pathname + location.search;
    analytics.setCurrentScreen(page_path);
    analytics.logEvent("page_view", {page_path});

    if(document.title !== DEFAULT_TITLE) {
      document.title = DEFAULT_TITLE;
    }
  }, [location]);

  return null;
}