
import React from "react";

import { Page, Text, StyleSheet } from "@react-pdf/renderer";
import produce from "immer";
import { ElementTypes, isGroupElement } from "../../components/types/elementTypes";
import { BasicScreenplayNode, GroupScreenplayNode } from "../../services/firebase";


// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: 'Courier Prime',
    fontSize: '12pt',
    padding: '1in',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    textAlign: 'left'
  },
  pageNumber: {
    position: 'absolute',
    top: 36,
    right: '1in',
    left: 0,
    textAlign: 'right'
  },
  sceneheader: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    lineHeight: '100%',
    paddingTop: 2,
    paddingLeft: 2,
    backgroundColor: 'lightgrey',
    marginTop: 18,
  },
  character: {
    textTransform: 'uppercase',
    padding: 0,
    paddingTop: 16,
    margin: 0,
    marginLeft: 2.5 * 72,
    textAlign: 'left'
  },
  parenthetical: {
    marginLeft: 2*72,
  },
  dialogue: {
    marginLeft: 72,
    marginRight: 1.5 * 72
  },
  transition: {
    textTransform: 'uppercase',
    marginLeft: 5*72,
    marginTop: 12
  },
  action: {
    marginTop: 12
  }
});

interface ScreenplayContentProps {
  document: Array<GroupScreenplayNode>;
  pageOffset?: number;
}



function reduceNavBlock(children: Array<GroupScreenplayNode | BasicScreenplayNode>): Array<BasicScreenplayNode> {
  return children?.reduce((result, next) => {
  if(isGroupElement(next)) {
    result.push(...reduceNavBlock(next.children));
  } else {
    result.push(next);
  }

  return result;
}, [] as Array<BasicScreenplayNode>) ?? [];
}

export const ScreenplayContent: React.FC<ScreenplayContentProps> = ({document, pageOffset}) => {
  const textNodes = reduceNavBlock(document);

  return (<Page size="letter" style={styles.page} wrap={true}>
  <Text style={styles.pageNumber} render={({pageNumber}) => (`${pageNumber + (pageOffset ?? 0)}`)} fixed/>
  {textNodes.map((node: BasicScreenplayNode, idx) => {
    const style = produce(styles[node.type as string], (draft) => {

    if(node.type === ElementTypes.SceneHeader && idx === 0)
      draft.marginTop = 0;
    });

    // @ts-ignore
    const nodeText = produce(node.children[0].text ?? "", (draft: string) => {
      if(node.type === ElementTypes.Parenthetical) return `(${draft})`;
      return draft;
    });

  return (<Text style={style}>{nodeText}</Text>);
})}
</Page>);
}