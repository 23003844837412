import React, { useState } from "react";

import {
  Avatar,
  IconButton,
  Popper,
  Paper,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from "@material-ui/core";

import { useUser } from "../providers/UserProvider";
import { signOut } from "../services/firebase/firebase";
import { useHistory } from "react-router-dom";

export const AccountMenu: React.FC = () => {
  const { user } = useUser();
  const history = useHistory();

  const [isAccountMenuOpen, setAccountMenuOpen] = useState(false);

  const accountMenuAnchor = React.useRef<HTMLButtonElement>(null);

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if(accountMenuAnchor.current && accountMenuAnchor.current.contains(event.target as HTMLElement)) {
      return;
    }

    setAccountMenuOpen(false);
  };

  const handleLogOut = () => {
    signOut();
    setAccountMenuOpen(false);
  };

  const handleSettings = () => history.push("/settings");

  const handleToggle = () => {
    setAccountMenuOpen((prevOpen) => !prevOpen);
  };

  return (
    <>
      <IconButton color="inherit" onClick={handleToggle} ref={accountMenuAnchor}>
        <Avatar
          alt={user?.displayName ?? "User"}
          src={user?.photoURL ?? undefined}
        />
      </IconButton>
      <Popper
        open={isAccountMenuOpen}
        anchorEl={accountMenuAnchor.current}
        role={undefined}
        transition
        disablePortal
      >{({TransitionProps}) => (
        <Grow {...TransitionProps} style={{transformOrigin: 'right top'}} >
          <Paper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList autoFocusItem={isAccountMenuOpen}>
                <MenuItem onClick={handleSettings}>Settings</MenuItem>
                <MenuItem onClick={handleLogOut}>Logout</MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}</Popper>
    </>
  );
};
