
import React from "react";

interface CaretParams {
  isForward: boolean;
  data: { name: string; color: string };
}

export const Caret: React.FC<CaretParams> = ({ data, isForward }) => {
  const cursorStyles = {
    ...cursorStyleBase,
    background: data.color,
    left: isForward ? "100%" : "0%",
  };
  const caretStyles = {
    ...caretStyleBase,
    background: data.color,
    left: isForward ? "100%" : "0%",
  };

  caretStyles[isForward ? "bottom" : "top"] = 0;

  return (
    <>
      <span contentEditable={false} style={caretStyles}>
        <span style={{ position: "relative" }}>
          <span contentEditable={false} style={cursorStyles}>
            {data.name}
          </span>
        </span>
      </span>
    </>
  );
};

const cursorStyleBase = {
  position: "absolute",
  top: 0,
  pointerEvents: "none",
  userSelect: "none",
  transform: "translateY(-100%)",
  fontFamily: "'Roboto', 'Helvetica', sans-serif",
  fontSize: 10,
  color: "white",
  background: "palevioletred",
  whiteSpace: "nowrap",
  padding: "0 4px"
} as any;

const caretStyleBase = {
  position: "absolute",
  pointerEvents: "none",
  userSelect: "none",
  height: "1.2em",
  width: 2,
  background: "palevioletred",
} as any;