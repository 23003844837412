import firebase from "firebase/app";
import "firebase/firestore";
import produce from "immer";



export interface InputScreenplayMetadata {
  title: string;
  description: string;
  writtenBy?: string;
  contactInformation?: string;
}

type FirestoreTimestamp = { seconds: number; nanoseconds: number };

export interface ScreenplayMetadata extends InputScreenplayMetadata {
  id: string;
  createdAt: FirestoreTimestamp;
  updatedAt: FirestoreTimestamp;
  ownedBy: string;
}

export const saveScreenplayMetadata = async (
  id: string | undefined,
  screenplay: InputScreenplayMetadata
): Promise<ScreenplayMetadata> => {
  firebase.analytics().logEvent("save_metadata", { isNew: id === undefined });

  const user = firebase.auth().currentUser;
  const doc = firebase
    .firestore()
    .collection(`screenplay`)
    .doc(id);

  const savingScreenplay = produce(screenplay, (draft: ScreenplayMetadata) => {
    if (!id) {
      // @ts-ignore
      draft.createdAt = new Date();
      draft.ownedBy = user!.uid;
    }

    // @ts-ignore
    draft.updatedAt = new Date();
  }) as ScreenplayMetadata;

  await doc.set(savingScreenplay, { merge: true });

  return {
    ...savingScreenplay,
    id: doc.id,
  };
};

export const listScreenplays = (
  uid: string
): firebase.firestore.Query<firebase.firestore.DocumentData> => {
  return firebase
    .firestore()
    .collection(`screenplay`)
    .where('ownedBy', '==', uid)
    .limit(25);
};

export const listSharedScreenplays = (uid: string): firebase.firestore.Query<firebase.firestore.DocumentData> => {
return firebase
  .firestore()
  .collection(`screenplay`)
  .where('sharedWith', 'array-contains', uid)
  .limit(25);
};

export const getScreenplay = (
  documentId: string
) => firebase
    .firestore()
    .collection(`screenplay`)
    .doc(documentId);