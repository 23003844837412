import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from "./reportWebVitals";

import "fontsource-roboto";

import { configFirebase } from "./services/firebase/firebase";
import { UserProvider } from "./providers/UserProvider";
import { MuiThemeProvider, CssBaseline } from "@material-ui/core";

import { defaultTheme } from "./themes";
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from "@stripe/stripe-js";
import {FlagsProvider, FlagsConfig} from "react-unleash-flags";

const stripeKey = process.env.REACT_APP_STRIPE_KEY;
if(!stripeKey) {
  throw new Error(`Stripe key not provided!`);
}
const stripePayments = loadStripe(stripeKey);

// eslint-disable-next-line no-restricted-globals
const isLocal = location.hostname === "localhost";

configFirebase(isLocal);

const flagConfig = {
  appName: isLocal ? "development" : "production",
  url: "https://gitlab.com/api/v4/feature_flags/unleash/23536449",
  instanceId: "tSgSPNHFVh2WC7M9Gpv2",
} as FlagsConfig;

ReactDOM.render(
  <React.StrictMode>
    <FlagsProvider config={flagConfig}>
      <Elements stripe={stripePayments}>
        <MuiThemeProvider theme={defaultTheme}>
          <CssBaseline />
          <UserProvider>
            <App />
          </UserProvider>
        </MuiThemeProvider>
        </Elements>
    </FlagsProvider>
  </React.StrictMode>,
  document.getElementById("root")
);



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
