import React, { useState, useEffect, createContext } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import { isCustomerSubscribed } from "../services/firebase";

interface UserProviderData {
  user: firebase.User | null;
  loading: boolean;
  isSubscribed: boolean;
}

const UserContext = createContext<UserProviderData>({ user: null, loading: true, isSubscribed: false});

export const UserProvider: React.FC = (props) => {
  const previousUser = localStorage.getItem('user');
  const [user, setuser] = useState<firebase.User | null>(previousUser ? JSON.parse(previousUser) : null);
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {

      const isSubscribed = await isCustomerSubscribed();
      setIsSubscribed(isSubscribed);

      if(user) {
        firebase.analytics().setUserId(user.uid);
        firebase.analytics().setUserProperties({
          ...user.metadata
        });
      }

      setuser(user);
      localStorage.setItem('user', JSON.stringify(user));
      setLoading(false);
    }, () => {
      localStorage.removeItem('user');
      setuser(null);
    });

    return () => unsubscribe();
  }, []);

  return (
    <UserContext.Provider value={{user, loading, isSubscribed}}>{props.children}</UserContext.Provider>
  );
};

export const useUser = () => React.useContext(UserContext);
