import React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { useUser } from "../providers/UserProvider";

export const AuthorizedRoute: React.FC<RouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { user } = useUser();

  return (
    <Route
      {...rest}
      render={(props) => {
        return user ? (
          // @ts-ignore
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/signin", state: { from: props.location } }}
          />
        );
      }
      }
    />
  );
};
