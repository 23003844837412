import React, {MouseEvent } from "react";
import { ReactEditor, RenderElementProps, useEditor } from "slate-react";
import { Node, Transforms } from "slate";
import styles from "../ScreenplayEditor.module.css";
import { ElementTypes } from "../types/elementTypes";
import { DOMNode } from "slate-react/dist/utils/dom";

export const GroupElement = (props:RenderElementProps): JSX.Element => {
  const isCollapsed = props.element.collapsed;
  const editor = useEditor();

  const toggleClicked = (event: MouseEvent) => {
    event.preventDefault();
    
    const node = ReactEditor.toSlateNode(editor, event.target as DOMNode);
    const path = ReactEditor.findPath(editor, node);

    Transforms.setNodes(editor, {collapsed: !isCollapsed}, {at: path});
  };

  const headerNode = Node.child(props.element, 0);
  const headerType = headerNode.type as ElementTypes;
  const headerContent = Node.string(headerNode);
  
  return (
  <div {...props.attributes} className={styles.group}>
    <div className={styles.collapse} contentEditable={false} onMouseDown={toggleClicked}>
      <i className={`fas ${isCollapsed ? "fa-caret-right" : "fa-caret-down"}`}></i>
    </div>
    <div contentEditable={false} className={styles[headerType]} style={{userSelect: "none", display: isCollapsed ? "block" : "none", cursor: "pointer"}} onMouseDown={toggleClicked}>
      {headerContent}
    </div>
    <div style={{display: isCollapsed ? "none" : "block"}}>
      {props.children}
    </div>
  </div>);
}