import React, { useEffect, useState } from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  LinearProgress,
} from "@material-ui/core";
import {
  getScreenplay,
  InputScreenplayMetadata,
  ScreenplayMetadata,
} from "../services/firebase/screenplays";
import { useUser } from "../providers/UserProvider";
import { useDocumentData } from "react-firebase-hooks/firestore";

export interface NewScreenplayDialogProps {
  id?: string;
  open: boolean;
  onClose: () => void;
  onSave: (screenplay: InputScreenplayMetadata) => void;
}

export const EditScreenplayMetadataDialog: React.FC<NewScreenplayDialogProps> = (
  props
) => {
  const {open, id} = props;
  const { user } = useUser();
  const [screenplay, isScreenplayLoading] = useDocumentData<ScreenplayMetadata>(id && getScreenplay(id));
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [writtenBy, setWrittenBy] = useState<string | undefined>(user?.displayName ?? undefined);
  const [contact, setContact] = useState<string | undefined>(`Email: ${user?.email}`);

  useEffect(() => {
    if(!open || !screenplay) return;

    const {title, description, writtenBy, contactInformation} = screenplay;

    setTitle(title);
    setDescription(description);
    setWrittenBy(writtenBy);
    setContact(contactInformation);
  }, [open, screenplay]);

  const onCancel = () => {
    setTitle("");
    setDescription("");
    props.onClose();
  };

  const onSave = () => {
    props.onClose();

    props.onSave({
      title,
      description,
      writtenBy,
      contactInformation: contact
    });
  };

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth>
      <DialogTitle>{id ? title : "New Screenplay"}</DialogTitle>
      {isScreenplayLoading && <LinearProgress />}
      <DialogContent>
        <TextField
          fullWidth
          label="Title"
          focused
          margin="normal"
          InputLabelProps={{ shrink: true }}
          value={title}
          onChange={(event) => setTitle(event.target.value)}
        />
        <TextField
          fullWidth
          label="Written By"
          margin="normal"
          InputLabelProps={{ shrink: true }}
          value={writtenBy}
          onChange={(event) => setWrittenBy(event.target.value)}
        />
        <TextField
          fullWidth
          label="Contact Information"
          margin="normal"
          InputLabelProps={{ shrink: true }}
          multiline
          value={contact}
          onChange={(event) => setContact(event.target.value)}
        />
        <TextField
          fullWidth
          label="Description"
          margin="normal"
          InputLabelProps={{ shrink: true }}
          multiline
          value={description}
          onChange={(event) => setDescription(event.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          variant="contained"
          onClick={onSave}
          color="primary"
        >
          {id ? "Save" : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
