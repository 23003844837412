import { Document, Font } from '@react-pdf/renderer';
import React from 'react';
import { GroupScreenplayNode } from '../../services/firebase';
import { ScreenplayMetadata } from '../../services/firebase/screenplays';
import { ScreenplayContent } from './mainContent';
import { TitlePage } from './titlePage';

/**
 * Formatting References:
 * https://www.arcstudiopro.com/blog/ultimate-guide-to-screenplay-formatting/#technical-formatting
 */

Font.register({
  family: 'Courier Prime',
  fonts: [
    { src: process.env.PUBLIC_URL + '/fonts/Courier Prime.ttf' },
    { src: process.env.PUBLIC_URL + '/fonts/Courier Prime Bold.ttf', fontWeight: 700 },
    { src: process.env.PUBLIC_URL + '/fonts/Courier Prime Italic.ttf', fontStyle: 'italic' },
    { src: process.env.PUBLIC_URL + '/fonts/Courier Prime Bold Italic.ttf', fontWeight: 700, fontStyle: 'italic' },
  ]
});


export interface ScreenplayDocumentProps {
  document: Array<GroupScreenplayNode>;
  metadata?: ScreenplayMetadata;
}

// Create Document Component
export const ScreenplayDocument: React.FC<ScreenplayDocumentProps> = (props) => {

  const screenplay = {
    title: "Screenplay Title",
    author: "Someone",
    ...props.metadata,
  } as ScreenplayMetadata;
  
  
  return (
    <Document title={screenplay.title} author={screenplay.writtenBy} creator="Scenes.Studio">
      <TitlePage screenplay={screenplay} />
      <ScreenplayContent document={props.document} pageOffset={-1} />
    </Document>
  );
};