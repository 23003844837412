import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Login } from "./pages/authentication/Login";
import {Dashboard} from "./pages/Dashboard";
import { ScreenplayEditorPage } from "./pages/ScreenplayEditor";
import { FirebaseAnalytics } from "./components/FirebaseAnalytics";
import { SettingsPage } from "./pages/Settings";
import { AuthorizedRoute } from "./router/AuthorizedRoute";
import { AddSharedDocument } from "./pages/AddSharedDocument";

const App = () => {
  return (
    <Router>
      <div className="App" style={{padding: 0, margin: 0}}>
        <FirebaseAnalytics />
        <Switch>
          <Route exact path="/signin" component={Login} />
          <AuthorizedRoute exact path="/" component={Dashboard} />
          <AuthorizedRoute path="/share/:linkId" component={AddSharedDocument} />
          <AuthorizedRoute path="/edit/:documentId" component={ScreenplayEditorPage} />
          <AuthorizedRoute path="/settings" component={SettingsPage} />
        </Switch>
      </div>
    </Router>
  );
};

export default App;
