
import React from "react";
import { ScreenplayMetadata } from "../services/firebase/screenplays";
import { Card, CardActions, CardContent, CardHeader, IconButton, makeStyles, Typography, ButtonBase } from "@material-ui/core";

import PrintIcon from "@material-ui/icons/PrintRounded";
import EditIcon from "@material-ui/icons/SettingsRounded";
import ShareIcon from "@material-ui/icons/ShareRounded";
import { useUser } from "../providers/UserProvider";

export interface ScreenplayCardProperties {
  screenplay: ScreenplayMetadata;
  onPrint: () => void;
  onEdit: () => void;
  onShare: () => void;
  onEditTitlePage: () => void;
}

const useStyles = makeStyles((theme) => {
  return {
    screenplayCard: {
      borderRadius: theme.spacing(1),
      height: "100%"
    },
    description: {
      lineHeight: "1em",
      maxHeight: "3em",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    contentArea: {
      paddingTop: 0,
      paddingBottom: 0
    },
    clickableArea: {
      display: 'block',
      textAlign: 'initial',
      width: "100%"
    },
    actions: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%"
    }
  }
});

export const ScreenplayCard: React.FC<ScreenplayCardProperties> = (props) => {
  const classes = useStyles();
  const { user, isSubscribed } = useUser();

  const {screenplay} = props;
  const ownedBy = user?.uid === screenplay.ownedBy;

  return (
    <Card className={classes.screenplayCard}>
      <ButtonBase onClick={props.onEdit} className={classes.clickableArea}>
      <CardHeader title={screenplay.title} subheader={new Date(screenplay.updatedAt.seconds * 1000).toDateString()} />
      <CardContent className={classes.contentArea}>
        <Typography className={classes.description}>{screenplay.description}</Typography>
      </CardContent>
      </ButtonBase>

      <CardActions disableSpacing className={classes.actions} >
        <IconButton aria-label="print screenplay" onClick={props.onPrint} title="Print">
          <PrintIcon />
        </IconButton>

        {isSubscribed && ownedBy && (<IconButton aria-label="share" onClick={props.onShare} title="Share">
          <ShareIcon />
        </IconButton>)}

        <IconButton aria-label="edit metadata" onClick={props.onEditTitlePage} title="Edit Title Page">
          <EditIcon />
        </IconButton>
      </CardActions>
    </Card>);
};
