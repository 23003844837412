import React from "react";
import { Editor, Node, Transforms } from "slate";

import { Editable, RenderElementProps, useEditor, RenderLeafProps } from "slate-react";


import styles from "./ScreenplayEditor.module.css";
import { SceneHeaderElement } from "./scriptElements/SceneHeader";
import { ActionElement } from "./scriptElements/Action";
import { ElementTypes, GroupElementTypes } from "./types/elementTypes";
import { CharacterElement } from "./scriptElements/Character";
import { ParentheticalElement } from "./scriptElements/Parenthetical";
import { DialogueElement } from "./scriptElements/Dialogue";
import { TransitionElement } from "./scriptElements/Transition";
import { GroupElement } from "./scriptElements/Group";

import "@fortawesome/fontawesome-free/css/all.css";
import { CursorEditor, useCursors } from "slate-yjs";

import {Caret} from "./Caret";

// https://screenwriting.io/what-is-standard-screenplay-format/

export interface ScreenplayEditorParameters {
}

const Leaf: React.FC<RenderLeafProps> = ({attributes, children, leaf}) => {
  // TODO: Apply leaf formatting

  const data = leaf.data as {alphaColor: string};

  return (<span {...attributes} style={{position: "relative", backgroundColor: data?.alphaColor}}>
    {leaf.isCaret ? <Caret {...(leaf as any)} /> : null}
    {children}
  </span>);
}

const renderElement = (props: RenderElementProps) => {
  switch (props.element.type) {
    case ElementTypes.SceneHeader:
      return <SceneHeaderElement {...props} />;
    case ElementTypes.Character:
      return <CharacterElement {...props} />;
    case ElementTypes.Parenthetical:
      return <ParentheticalElement {...props} />;
    case ElementTypes.Dialogue:
      return <DialogueElement {...props} />;
    case ElementTypes.Transition:
      return <TransitionElement {...props} />;
    case GroupElementTypes.NavigationBlock:
      return <GroupElement {...props} />;
    default:
      return <ActionElement {...props} />;
  }
};


export const ScreenplayEditor: React.FC<ScreenplayEditorParameters> = (
  props
) => {

  const editor = useEditor();

  const {decorate} = useCursors(editor as unknown as CursorEditor);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const renderLeaf = React.useCallback((props: any) => <Leaf {...props} />, [decorate]);

  const onKeyDown = (event: React.KeyboardEvent) => {
    const block = Editor.above(editor, {
      match: (n) => Editor.isBlock(editor, n),
    });

    if (block) {
      const content = Node.string(block[0]);
      const startingType = block[0].type;

      if (
        content.trim().length === 0 &&
        startingType === ElementTypes.Dialogue &&
        event.key === "("
      ) {
        event.preventDefault();
        Transforms.setNodes(editor, { type: ElementTypes.Parenthetical });
      }

      if (content.trim().length === 0 && event.key === "Enter") {
        event.preventDefault();

        if (startingType === ElementTypes.Action) {
          Transforms.setNodes(editor, { type: ElementTypes.SceneHeader });
        } else {
          Transforms.setNodes(editor, { type: ElementTypes.Action });
        }
      } else if (
        content.length === 0 &&
        event.key === "Backspace" &&
        startingType !== ElementTypes.Action
      ) {
        Transforms.setNodes(editor, { type: ElementTypes.Action });
      } else if (
        /^[A-Z]+$/.test(content.trim()) &&
        startingType === ElementTypes.Action &&
        event.key === "Enter"
      ) {
        Transforms.setNodes(editor, { type: ElementTypes.Character });
      }

      const newBlock = Editor.above(editor, {
        match: (n) => Editor.isBlock(editor, n),
      });

      const newType = newBlock?.[0].type;

      if (newType !== startingType) {
        if (newType === ElementTypes.SceneHeader) {
          Transforms.liftNodes(editor);
          Transforms.wrapNodes(editor, {
            type: GroupElementTypes.NavigationBlock,
            children: [],
          });
        } else if (startingType === ElementTypes.SceneHeader) {
          Transforms.mergeNodes(editor);
        }
      }
    }
  };

  return (
      <Editable
        spellCheck
        className={styles.screenplayEditor}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        onKeyDown={onKeyDown}
        decorate={decorate}
      />
  );
};
