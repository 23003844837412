import React from "react";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  LinearProgress,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {
  GroupScreenplayNode,
  loadScreenplayDocument,
  ScreenplayMetadata
} from "../services/firebase";
import { PDFViewer } from "@react-pdf/renderer";
import { ScreenplayDocument } from "../documentRenderers/screenplay";
import { useUser } from "../providers/UserProvider";

export interface PdfPreviewDialogProps {
  screenplay: ScreenplayMetadata;
  open: boolean;
  onClose: () => void;
  onError?: (error: string) => void;
}

const useStyles = makeStyles((theme) => ({
  previewWindow: {
    width: "100%",
    height: "50vh"
  }
}))


export const ScreenplayPdfPreview: React.FC<PdfPreviewDialogProps> = (props) => {
  const [document, setDocument] = React.useState<Array<GroupScreenplayNode> | null>(null);
  const screenplayId = props.screenplay.id;

  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { user } = useUser();

  const {onError} = props;

  React.useEffect(() => {
    loadScreenplayDocument(user!.uid, screenplayId).then((result) => {
      if(result?.document) {
        setDocument(result?.document);
      }
    }).catch((ex) => onError?.(ex.message));
  }, [screenplayId, onError, user]);

  return (
    <Dialog open={props.open} onClose={props.onClose} fullWidth maxWidth="md" fullScreen={fullScreen}>
    <DialogTitle>{props.screenplay.title}</DialogTitle>
      <DialogContent>
        {!document ? <LinearProgress /> :
        <PDFViewer className={classes.previewWindow}>
            <ScreenplayDocument document={document} metadata={props.screenplay} />
          </PDFViewer>}
          <DialogActions>
            <Button onClick={props.onClose}>Close</Button>
          </DialogActions>
      </DialogContent>
    </Dialog>
  )
};