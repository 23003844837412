import React from "react";
import {useParams, Redirect} from "react-router-dom";
import { LoadingContent } from "../components/LoadingContent";
import { addSharedScreenplay } from "../services/firebase";

interface AddSharedDocumentParams {
  linkId: string;
}

export const AddSharedDocument: React.FC = () => {
  const {linkId} = useParams<AddSharedDocumentParams>();
  const [documentId, setDocumentId] = React.useState<string|null>(null);
  
  React.useEffect(() => {
    if(!linkId) return;
    addSharedScreenplay({linkId}).then(({documentId}) => {
      setDocumentId(documentId);
    })
  }, [linkId]);

  if(documentId) {
    return <Redirect to={{pathname: `/edit/${documentId}`}} />
  }

  return <LoadingContent title="Getting Shared Document" />
}