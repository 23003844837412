import React from "react";


import { Grid, makeStyles, Typography, Card, CardContent, LinearProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100vh",
    backgroundColor: theme.palette.background.default
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

interface LoadingContentProps {
  title?: string;
}

export const LoadingContent: React.FC<LoadingContentProps> = (props) => {
  const classes = useStyles();

  return (
      <Grid container justify="center" alignContent="center" className={classes.root}>
        <Grid item xs={6} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6">{props.title ?? "Loading..."}</Typography>
              <LinearProgress />
            </CardContent>
            </Card>
        </Grid>
      </Grid>
  );
};
