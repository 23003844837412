import { Editor, Transforms, Node, Range } from "slate";
import { ElementTypes } from "../components/types/elementTypes";

export function setGroup(editor: Editor, type: ElementTypes) {
  

  // Transforms.liftNodes(editor);
  // Transforms.wrapNodes(editor, { type: ElementTypes.Group, children: [] });

  const splitLocation = Range.start(editor.selection!).path.slice(0, -1);

  Transforms.splitNodes(
    editor,
    {
      at: splitLocation
    }
  );

  Transforms.setNodes(
    editor,
    { type },
    { match: (n) => Editor.isBlock(editor, n) }
  );
}

export const withAutoFormatting = <T extends Editor>(editor: T): T => {
  const { insertText, insertBreak } = editor;

  // const StickyElements = ["synopses", "section"];
  // const AutoFormatRegexes = {
  //   action: /^!.*$/,
  //   sceneheader: /^(\.)|(int\.|ext\.|est\.|i\.?\/e\.?).*$/i,
  //   // 'center': /^>.*<$/,
  //   transition: /^(>)|([A-Z -]*TO:$)/i,
  //   // 'linebreak': /^====$/,
  //   // 'synopses': /^=.*$/,
  //   // 'section': /^#+.*$/
  // } as Record<ElementTypes, RegExp>;

  const sceneHeaderRx = /^(\.)|(int\.|ext\.|est\.|i\.?\/e\.?).*$/i;

  editor.insertText = (text) => {
    const block = Editor.above(editor, {
      match: (n) => Editor.isBlock(editor, n),
    });
    if (block) {
      const value = Node.string(block[0]) + text;

      if (sceneHeaderRx.test(value)) {
        const blockPath = Range.start(editor.selection!).path;
        const isFirst = blockPath[blockPath.length - 2] === 0;

        if(!isFirst)
        setGroup(editor, ElementTypes.SceneHeader);
      }
    }

    insertText(text);
  };

  editor.insertBreak = () => {
    const block = Editor.above(editor, {
      match: (n) => Editor.isBlock(editor, n),
    });
    const prevType = block?.[0].type;

    insertBreak();

    // Set new block type based on previous
    switch (prevType) {
      case ElementTypes.Transition:
        setGroup(editor, ElementTypes.SceneHeader);
        break;

      case ElementTypes.Character:
      case ElementTypes.Parenthetical:
        Transforms.setNodes(editor, { type: ElementTypes.Dialogue });
        break;

      case ElementTypes.Dialogue:
        Transforms.setNodes(editor, { type: ElementTypes.Character });
        break;

      default:
        Transforms.setNodes(editor, { type: ElementTypes.Action });
    }
  };

  return editor;
};
