import React from "react";

import { GoogleLoginButton } from "react-social-login-buttons";
import { signInWithGoogle, signInWithEmail } from "../../services/firebase/firebase";
import {Redirect, useLocation} from "react-router-dom";

import { Grid, makeStyles, Typography, Card, CardHeader, CardContent, CardActionArea, TextField, InputAdornment, IconButton, Button } from "@material-ui/core";
import { useUser } from "../../providers/UserProvider";

import Visibility from '@material-ui/icons/VisibilityRounded';
import VisibilityOff from '@material-ui/icons/VisibilityOffRounded';
import EmailIcon from "@material-ui/icons/EmailRounded";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: "100vh",
    backgroundColor: theme.palette.background.default
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  inputField: {
    marginBottom: theme.spacing(2),
  },
  loginButton: {
    width: "100%"
  }
}));

export const Login: React.FC = (props) => {
  const classes = useStyles();
  const location = useLocation<{from?: {pathname: string; state?: unknown}}>();
  const {user} = useUser();
  const [email, setEmail] = React.useState<string | null>(null);
  const [password, setPassword] = React.useState<string | null>(null);
  const [isPasswordVisible, setPasswordVisible] = React.useState(false);

  if(user) {
    const from = location.state.from ?? {pathname: "/"};

    return (<Redirect to={from} />);
  }

  return (
      <Grid container justify="center" alignContent="center" className={classes.root}>
        <Grid item xs={6} md={3}>
          <Card className={classes.paper}>
            <CardHeader title="Login" />

            <CardContent>


              <TextField label="Email" value={email} onChange={(event) => setEmail(event.target.value)} fullWidth variant="outlined" className={classes.inputField} />

              <TextField label="Password" value={password} onChange={(event) => setPassword(event.target.value)} type={isPasswordVisible ? "text" : "password"} fullWidth 
              className={classes.inputField} variant="outlined" 
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton aria-label="toggle password visibility"
                    onClick={() => setPasswordVisible((isVisible) => !isVisible)}>
                      {isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }} />

              <Button variant="contained" color="primary" onClick={() => signInWithEmail(email!, password!)} className={classes.loginButton}><EmailIcon style={{marginRight: "16px"}} /><Typography>Login with Email</Typography></Button>
            </CardContent>

            <CardActionArea>
              <GoogleLoginButton onClick={signInWithGoogle} />
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
  );
};
