import React from "react";

import {
  makeStyles,
  AppBar,
  Typography,
  Toolbar,
  FormGroup,
  Grid,
  Paper,
  Divider,
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  DialogActions,
  TextField,
  Select,
  MenuItem,
  LinearProgress,
  Radio,
  ListItemSecondaryAction,
  ListItem,
  ListItemText,
  List,
  ListSubheader,
  ListItemIcon,
  Fab,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { AccountMenu } from "../components/AccountMenu";

import { UserContent, ProfileContent } from "functions/src";

import HomeIcon from "@material-ui/icons/HomeRounded";
import DeleteIcon from "@material-ui/icons/DeleteRounded";
import SaveIcon from "@material-ui/icons/SaveRounded";

import { useHistory } from "react-router";

import deepEqual from "deep-equal";

import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { PaymentMethod, StripeCardElementChangeEvent } from "@stripe/stripe-js";
import {
  addStripePaymentMethod,
  CustomerDetails,
  deletePaymentMethod,
  getCustomerDetailsDoc,
  getPaymentSources,
  getUserDoc,
  setCustomerSubscription,
  updateUserProfile,
} from "../services/firebase";
import { useUser } from "../providers/UserProvider";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";

const useStyles = makeStyles((theme) => {
  return {
    title: {
      flexGrow: 1,
    },
    mainGrid: {
      justifyContent: "center",
      marginTop: theme.spacing(2),
    },
    settingsPaper: {
      padding: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },
    formField: {
      marginBottom: theme.spacing(3),
    },
    fab: {
      position: "absolute",
      bottom: theme.spacing(3),
      right: theme.spacing(3),
    },
  };
});

export const SettingsPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useUser();

  const subscriptionTiers = [
    {
      name: "Free",
      id: "free",
    },
    {
      name: "Monthly ($7)",
      id: "price_1IJmaBLKDBMyiOCqMJVq2yRW",
    },
    {
      name: "Yearly ($65)",
      id: "price_1IJmaBLKDBMyiOCqBa8NjG1l",
    },
  ];

  const [showNewPaymentDialog, setShowNewPaymentDialog] = React.useState(false);
  const [paymentMethods, paymentMethodsLoading] = useCollectionData<
    PaymentMethod & { documentId: string }
  >(getPaymentSources(user!.uid), { idField: "documentId" });
  const [savedCustomerDetails, customerDetailsLoading] = useDocumentData<
    CustomerDetails
  >(getCustomerDetailsDoc(user!.uid));

  const [customerDetails, setCustomerDetails] = React.useState<
    Partial<CustomerDetails>
  >();

  const [userDataDoc, isUserDataLoading] = useDocumentData<UserContent>(
    getUserDoc(user!.uid)
  );

  const [profile, setProfile] = React.useState<ProfileContent>();

  React.useEffect(() => {
    setCustomerDetails(savedCustomerDetails);
  }, [savedCustomerDetails]);

  React.useEffect(() => {
    setProfile(userDataDoc?.profile);
  }, [userDataDoc]);

  const isLoading = React.useMemo(
    () => paymentMethodsLoading || customerDetailsLoading || isUserDataLoading,
    [paymentMethodsLoading, customerDetailsLoading, isUserDataLoading]
  );

  const onGoHome = () => history.push("/");

  const onNewPaymentMethodClosed = async (paymentMethod?: PaymentMethod) => {
    setShowNewPaymentDialog(false);
  };

  const isSubscriptionChanged = React.useMemo(
    () => !deepEqual(savedCustomerDetails, customerDetails, { strict: true }),
    [savedCustomerDetails, customerDetails]
  );

  const isProfileChanged = React.useMemo(
    () => !deepEqual(profile, userDataDoc, { strict: true }),
    [profile, userDataDoc]
  );

  const isChanged = React.useMemo(
    () => isSubscriptionChanged || isProfileChanged,
    [isSubscriptionChanged, isProfileChanged]
  );

  const onSave = async () => {
    if (customerDetails && isSubscriptionChanged)
      await setCustomerSubscription(user!.uid, customerDetails);

    if (profile && isProfileChanged) {
      await updateUserProfile(user!.uid, profile);
    }
  };

  const onDeletePaymentMethod = React.useCallback(
    async (paymentMethod: PaymentMethod) => {
      if (!user) return;

      await deletePaymentMethod(user.uid, paymentMethod.id);
    },
    [user]
  );

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <IconButton onClick={onGoHome} edge="start" color="inherit">
            <HomeIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Settings
          </Typography>
          <AccountMenu />
        </Toolbar>
      </AppBar>
      {isLoading && <LinearProgress />}
      <Grid container className={classes.mainGrid}>
        <Grid item xs={12} md={6}>
          <Paper className={classes.settingsPaper}>
            <Typography variant="h4">Profile</Typography>
            <Divider />
            <TextField
              fullWidth
              className={classes.formField}
              value={profile?.name}
              label="Name"
              onChange={(event) =>
                setProfile((profile) => ({
                  ...profile,
                  name: event.target.value,
                }))
              }
            />

            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={profile?.public}
                    onChange={(event) =>
                      setProfile((profile) => ({
                        ...profile,
                        public: event.target.checked,
                      }))
                    }
                  />
                }
                label="Public"
              />
            </FormGroup>
          </Paper>
          <Paper className={classes.settingsPaper}>
            <Typography variant="h4">Subscription</Typography>
            <Divider />
            <Select
              value={customerDetails?.subscriptionPrice ?? "free"}
              label="Subscription Tier"
              onChange={(event) =>
                setCustomerDetails((customerDetails) => ({
                  ...customerDetails,
                  subscriptionPrice: event.target.value as string,
                }))
              }
              className={classes.formField}
              fullWidth
            >
              {subscriptionTiers.map((tier) => (
                <MenuItem value={tier.id} key={tier.name}>
                  <Typography variant="h6">{tier.name}</Typography>
                </MenuItem>
              ))}
            </Select>
            {Boolean(paymentMethods?.length) && (
              <List component="div" dense className={classes.formField}>
                <ListSubheader>Payment Methods</ListSubheader>
                {paymentMethods?.map((paymentMethod) => (
                  <PaymentMethodOption
                    key={paymentMethod.id}
                    paymentMethod={paymentMethod}
                    selected={
                      paymentMethod.id === customerDetails?.defaultPaymentId
                    }
                    onDelete={onDeletePaymentMethod}
                    onSelected={(paymentMethod) =>
                      setCustomerDetails((customerDetails) => ({
                        ...customerDetails,
                        defaultPaymentId: paymentMethod.id,
                      }))
                    }
                  />
                ))}
              </List>
            )}

            <FormGroup row className={classes.formField}>
              <Button onClick={() => setShowNewPaymentDialog(true)}>
                Add Payment Method
              </Button>
            </FormGroup>
          </Paper>
        </Grid>
      </Grid>

      <Fab
        className={classes.fab}
        color="primary"
        disabled={!isChanged}
        onClick={onSave}
      >
        <SaveIcon />
      </Fab>
      <NewPaymentDialog
        open={showNewPaymentDialog}
        onClose={onNewPaymentMethodClosed}
      />
    </>
  );
};

interface PaymentMethodOptionParameters {
  paymentMethod: PaymentMethod & { documentId: string };
  selected?: boolean;
  onSelected: (paymentMethod: PaymentMethod) => void;
  onDelete: (paymentMethod: PaymentMethod) => void;
}

const PaymentMethodOption: React.FC<PaymentMethodOptionParameters> = ({
  paymentMethod,
  selected,
  onSelected,
  onDelete,
}) => {
  let primary = `${paymentMethod.card?.brand} Ending in ${paymentMethod.card?.last4}`;

  if (paymentMethod.metadata.description) {
    primary = `${paymentMethod.metadata.description} (${primary})`;
  }

  return (
    <ListItem selected={selected}>
      <ListItemIcon>
        <Radio checked={selected} onChange={() => onSelected(paymentMethod)} />
      </ListItemIcon>
      <ListItemText
        primary={primary}
        secondary={`Expires ${paymentMethod.card?.exp_month}/${paymentMethod.card?.exp_year}`}
      />
      <ListItemSecondaryAction>
        <IconButton onClick={() => onDelete(paymentMethod)}>
          <DeleteIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

interface NewPaymentDialogProps {
  open: boolean;
  onClose: (paymentMethod?: PaymentMethod) => void;
}

const NewPaymentDialog: React.FC<NewPaymentDialogProps> = ({
  open,
  onClose,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const { user } = useUser();
  const classes = useStyles();

  const [isInvalid, setIsInvalid] = React.useState(true);
  const [cardErrors, setCardErrors] = React.useState<string | undefined>(
    undefined
  );
  const [customerName, setCustomerName] = React.useState<string>(
    user?.displayName ?? ""
  );
  const [cardDescription, setCardDescription] = React.useState<string>("");

  const addPaymentMethod = async () => {
    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);

    if (!card) {
      return;
    }

    const paymentMethod = await addStripePaymentMethod(
      stripe,
      card,
      customerName,
      cardDescription
    );
    onClose(paymentMethod);
  };

  const handleCardChange = (event: StripeCardElementChangeEvent) => {
    setIsInvalid(!event.complete);
    setCardErrors(event.error?.message);
  };

  const handleClose = () => onClose();

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogContent>
        <DialogTitle>New Payment Method</DialogTitle>
        <TextField
          value={customerName}
          onChange={(event) => setCustomerName(event.target.value)}
          label="Name on Card"
          fullWidth
          className={classes.formField}
        />
        <TextField
          value={cardDescription}
          onChange={(event) => setCardDescription(event.target.value)}
          label="Card Description"
          fullWidth
          className={classes.formField}
        />
        <CardElement onChange={handleCardChange} />
        <Typography>{cardErrors}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={addPaymentMethod}
          disabled={isInvalid}
          color="primary"
          variant="contained"
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};
