import firebase from "firebase/app";
import "firebase/firestore";
import produce from "immer";
import { Stripe, StripeCardElement, PaymentMethod } from "@stripe/stripe-js";


export const addStripePaymentMethod = async (
  stripe: Stripe,
  card: StripeCardElement,
  customerName: string,
  description: string
): Promise<PaymentMethod> => {
  const user = firebase.auth().currentUser!;

  const payload = await stripe.createPaymentMethod({
    type: "card",
    card,
    billing_details: {
      email: user.email ?? undefined,
      name: customerName,
    },
    metadata: {
      description,
    },
  });

  if (payload.error) {
    console.error(
      `Error Adding Stripe Payment Method: ${JSON.stringify(payload.error)}`
    );
    throw new Error(payload.error.message);
  }

  const docRef = firebase
    .firestore()
    .collection(`customer/${user.uid}/paymentMethod`)
    .doc();

  await docRef.set(payload.paymentMethod!);

  return payload.paymentMethod!;
};

export interface CustomerDetails {
  id: string;
  defaultPaymentId?: string;
  subscriptionPrice?: string | null;
}

export const getCustomerDetailsDoc = (uid: string) =>
  firebase.firestore().collection("customer").doc(uid);

export const setCustomerSubscription = (
  uid: string,
  customer: Partial<CustomerDetails>
) =>
  firebase
    .firestore()
    .collection("customer")
    .doc(uid)
    .set(
      produce(customer, (draft) => {
        if (draft.subscriptionPrice === "free") {
          draft.subscriptionPrice = null;
        }
      }),
      { merge: true }
    );

export const isCustomerSubscribed = async (): Promise<boolean> => {
  const tokenResult = await firebase.auth().currentUser?.getIdTokenResult();
  return tokenResult?.claims.isSubscribed === true;
};

export const getPaymentSources = (uid: string) =>
  firebase.firestore().collection(`customer/${uid}/source`);

export const deletePaymentMethod = (uid: string, paymentMethodId: string) =>
  firebase
    .firestore()
    .collection(`customer/${uid}/source`)
    .doc(paymentMethodId)
    .delete();